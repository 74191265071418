import React from 'react';
import { Col, Row, Container, Card, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { imagesContent } from '../../../../components/base-saction/AboutBali';


export interface BaliholidyasVillassSectionProps { }

export function BaliholidyasVillassSection(Props: BaliholidyasVillassSectionProps) {

    let slider: any[] = [];
    for (let data of imagesContent["Bali Villas"]) {
        slider.push(
            <div style={{ padding: '0 5px' }}>
                <Card style={{ width: '100%', textAlign: 'center' }}>
                    <Card.Img variant="top" src={data.url} />
                    <Card.Body>
                        <Card.Title>{data.title}</Card.Title>
                        <Card.Text>{data.description}</Card.Text>
                        <Button href="https://balivillasandmore.com" variant="primary">Check Availability</Button>
                    </Card.Body>
                </Card>
            </div>

        )
    }
    let settings: any = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        centerPadding: 20,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };
    return (
        <section style={{ margin: 0, padding: 0 }}>
            <Row>

                <Col className="home-feature-image col-md-push-6" md={6} style={{ backgroundImage: "linear-gradient(to right, #803146 , #b4435f)", padding: "30px " }}>
                    <div style={{ maxWidth: 570, color: '#fff', float: "right" }}>
                        <h3>Bali Villas</h3>
                        <p>Choose from Bali’s finest villas for rent. Enjoy the luxury of your own private pool, a team of staff to make sure your stay is perfect and room to socialize with family and friends. We’ve searched the island to bring you only the best of the best when it comes to Bali villas!</p>
                        <ul>
                            <li> Hassle free holiday planning
                            </li>
                            <li>
                                Fantastic villas at great prices
                            </li>
                            <li>
                                Villas across the island
                            </li>
                            <li>	Airport transfers included</li>
                        </ul>
                    </div>

                </Col>
                <Col className="home-feature-image col-md-pull-6" md={6} style={{ backgroundImage: `url("/assets/images/villa_siri_mendira_bali.jpg")` }}>

                </Col>
            </Row>
            <div style={{ background: "url('/assets/images/bali-beaches-background.jpg')", backgroundPosition: 'center', backgroundSize: 'cover', paddingBottom: 30 }}>
                <Container style={{ marginTop: '-100px' }}>
                    <Slider {...settings}>
                        {slider}
                    </Slider>
                    <p style={{ textAlign: 'center', margin: '30px' }}>
                        <Button href="https://balihotelsandmore.com"> Visit Bali Villas and Get Special Offer </Button>
                    </p>
                </Container>
            </div>

        </section>

    )

}
