import React from 'react';
import { Container } from 'react-bootstrap'
export interface MainIndexHeaderProps { }


export function MainIndexHeader(Props: MainIndexHeaderProps) {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(https://storage.googleapis.com/bali-hotel-images/bedugul-banner.jpg)",
          height: '700px', maxHeight: 700
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Bali Holidays And More </h1>
              <h2>Bring You The Best Hotels, Villas And Tours In Bali</h2>
              <h2>Talk To Us About Your Bali Travel Plans!</h2>
              <div className="fog-low">
                <img alt="..." src="/assets/images/fog-low.png" />
              </div>
              <div className="fog-low right">
                <img alt="..." src="/assets/images/fog-low.png" />
              </div>
            </div>
            <h2 className="presentation-subtitle text-center">

            </h2>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(/assets/images/clouds.png)"
          }}
        />

      </div>
    </>
  )

}
