import React from 'react';

import { MainIndexHeader } from '../MainIndexHeader';
import { BaliHolidyasNavBar } from '../../../../components/nav-bar/BaliHolidyasNavBar';
import { BaliholidyasSection1 } from './BaliholidyasSection1';
import { BaliholidyasHotelsSection } from './BaliholidyasHotelsSection';
import { BaliholidyasVillassSection } from './BaliholidyasVillassSection';
import { BaliholidyasToursSection } from './BaliholidyasToursSection';
import { BaliholidysdVipSection } from './BaliholidysdVipSection';
import { InThePress } from '../../../../components/base-saction/InthePress';
import { Partner } from '../../../../components/base-saction/Partner';
import { StellasChild } from '../../../../components/base-saction/StellasChild';

export interface HomePageProps { }


export function HomePage(Props: HomePageProps) {
  return (
    <>
      <style>
        {
          `
          .home-feature-image {
            background-position: center;
            background-size: cover;
            min-height: 450px;
        }
        ul li {
          -webkit-font-smoothing: antialiased;
          font-family: "Montserrat", "Helvetica", Arial, sans-serif;
          font-weight: 300;
        }

          `
        }
      </style>
      <BaliHolidyasNavBar />
      <MainIndexHeader />
      <BaliholidyasSection1 />
      <BaliholidyasHotelsSection />
      <BaliholidyasVillassSection />
      <BaliholidyasToursSection />
      <BaliholidysdVipSection />
      <StellasChild />
      <Partner />
      <InThePress />
    </>
  )


}