import React from 'react';
import { Col, Row, Container, Card, Button } from 'react-bootstrap';
import { imagesContent } from '../../../../components/base-saction/AboutBali';
import Slider from 'react-slick';

export interface BaliholidyasHotelsSectionProps { }
export function BaliholidyasHotelsSection(Props: BaliholidyasHotelsSectionProps) {

    let slider: any[] = [];
    for (let data of imagesContent["Bali Hotels"]) {
        slider.push(
            <div style={{ padding: '0 5px' }}>
                <Card style={{ width: '100%', textAlign: 'center' }}>
                    <Card.Img variant="top" src={data.url} />
                    <Card.Body>
                        <Card.Title>{data.title}</Card.Title>
                        <Card.Text>{}</Card.Text>
                        <Button href="https://balihotelsandmore.com" variant="primary">Check Availability</Button>
                    </Card.Body>
                </Card>
            </div>

        )
    }
    let settings: any = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        centerPadding: 20,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };
    return (
        <section style={{ marginBottom: 0, padding: 0 }}>
            <Row>
                <Col className="home-feature-image" md={6} style={{ backgroundImage: `url("/assets/images/holidays-in-bali-best-hotels.jpg")` }}>

                </Col>
                <Col className="home-feature-image" md={6} style={{ backgroundImage: "linear-gradient(to right, #028dd0 , #0367a5)", padding: "30px " }}>
                    <div style={{ maxWidth: 570, color: '#fff' }}>
                        <h3>Bali Hotels</h3>
                        <p> Choose from 1600 of Bali’s best hotels. If it’s beachfront luxury or a hideout in the hills you’re looking for, no matter what your budget, you’ll find the perfect hotel for you right here. With options to book now and pay later, and our Bali travel experts on hand to answer any questions, getting a great deal on your Bali hotel has never been easier.
                        </p>
                        <p> Book today and save! </p>
                        <ul>
                            <li> Browse, book and pay securely online </li>
                            <li> Exclusive discounts </li>
                            <li> Airport transfers included</li>
                            <li> Book your hotel for only $1 deposit and pay 14 days before arrival</li>
                        </ul>
                    </div>

                </Col>
            </Row>
            <div style={{ background: "url('/assets/images/bali-beaches-background.jpg')", backgroundPosition: 'center', backgroundSize: 'cover', paddingBottom: 30 }}>
                <Container style={{ marginTop: '-100px' }}>
                    <Slider {...settings}>
                        {slider}
                    </Slider>
                    <p style={{ textAlign: 'center', margin: '30px' }}>
                        <Button href="https://balihotelsandmore.com"> Visit Bali Hotels and Get Special Offer </Button>
                    </p>
                </Container>
            </div>

        </section>

    )

} 