import React from 'react';
import { Col, Row, Container, Card, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { imagesContent } from '../../../../components/base-saction/AboutBali';

export interface BaliholidyasToursSectionProps {

}

export function BaliholidyasToursSection(Props: BaliholidyasToursSectionProps) {
    let slider: any[] = [];
    for (let data of imagesContent["Bali Activities"]) {
        slider.push(
            <div style={{ padding: '0 5px' }}>
                <Card style={{ width: '100%', textAlign: 'center' }}>
                    <Card.Img variant="top" src={data.url} />
                    <Card.Body>
                        <Card.Title>{data.title}</Card.Title>
                        <Card.Text>{}</Card.Text>
                        <Button href="https://balitoursandmore.com" variant="primary">Explore Bali Tours</Button>
                    </Card.Body>
                </Card>
            </div>

        )
    }
    let settings: any = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        centerPadding: 20,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };
    return (
        <section style={{ margin: 0, padding: 0 }}>
            <Row>
                <Col className="home-feature-image" md={6} style={{ backgroundImage: `url("/assets/images/Bedugul.jpg")` }}>

                </Col>
                <Col className="home-feature-image" md={6} style={{ backgroundImage: "linear-gradient(to right, #8a498b , #663867)", padding: "30px " }}>
                    <div style={{ maxWidth: 570, color: '#fff' }}>
                        <h3>Bali Tours</h3>
                        <p>Experience more of Bali with Bali Tours and More. Witness the fascinating culture and temples, stunning beaches, dramatic volcanoes and take part in the opportunities for adventure sports. Our expert guides are on hand to share the best of the Island of the Gods with you.</p>
                        <ul>
                            <li>
                                See the best of Bali with us
                            </li>
                            <li>
                                Save up to 45% off with the Bali Tours Super Pass
                            </li>
                            <li>
                                Fully inclusive tours
                            </li>
                            <li>Discounted rates when you also book your accommodation with us</li>
                        </ul>
                    </div>

                </Col>
            </Row>
            <div style={{ background: "url('/assets/images/bali-beaches-background.jpg')", backgroundPosition: 'center', backgroundSize: 'cover', paddingBottom: 30 }}>
                <Container style={{ marginTop: '-100px' }}>
                    <Slider {...settings}>
                        {slider}
                    </Slider>
                    <p style={{ textAlign: 'center', margin: '30px' }}>
                        <Button href="https://balihotelsandmore.com"> Visit Bali Tours and Get Special Offer </Button>
                    </p>
                </Container>
            </div>

        </section>

    )

}
