import React from 'react';
import { Container, Row, Col, Media } from 'react-bootstrap';
const Why: any[] = [
    { icon: "/assets/images/icons/home/free-airport-transfer.png", altText: '', text: 'FREE Airport Transfers Included* ' },
    { icon: "/assets/images/icons/home/bali-staff.png", altText: '', text: 'Your Own Dedicated Customer Support Staff That Live In Bali' },
    { icon: "/assets/images/icons/home/corporate.png", altText: '', text: 'Corporate Events, Weddings Parties And Special Events Catered For' },
    { icon: "/assets/images/icons/home/tax-included.png", altText: '', text: 'All Taxes And Service Fees Included - No Hidden Extra Charges' },
    { icon: "/assets/images/icons/home/free-interest.png", altText: '', text: 'Interest-Free Payment Options ' },
    { icon: "/assets/images/icons/home/charity-support.png", altText: '', text: 'We Support Local Bali Charities. Your Booking Helps Bali Locals' },
]

export interface BaliholidyasSection1Props { }

export function BaliholidyasSection1(Props: BaliholidyasSection1Props) {
    let why: any[] = [];
    for (let w of Why) {
        why.push(
            <Col md={4}>
                <Media>
                    <img
                        width={64}
                        height={64}
                        className="mr-3"
                        src={w.icon}
                        alt={w.altText}
                    />
                    <Media.Body>
                        <h5>{w.text}</h5>
                    </Media.Body>
                </Media>
            </Col>
        )
    }
    return (
        <section >
            <Container >
                <h2 style={{ textAlign: "center" }}>Why Book With Us? </h2>
                <hr />
                <Row>
                    {why}
                </Row>
            </Container>
        </section>

    )

}
