import React from 'react';
import { Container } from 'react-bootstrap';

const Press: any[] = [
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/abc-radio-australia.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/abc.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/ask.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/australian-business-review.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/big-spring-herald.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/bing-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/cbs-nes.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/cnn.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/daily-herald.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/daily-media-press-group.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/daily-times.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/fox-nes.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/google-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/karn-news-radio.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/marketplaces.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/nbc.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/pilot-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/sbs.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/startribun.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/street-insider.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/the-evening.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/the-morning-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/the-post-and-mail.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/travel-industry-today.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/tripadvisor.jpg", url: "https://www.tripadvisor.com/Attraction_Review-g303952-d13993230-Reviews-Bali_Tours_And_More-Kintamani_Bangli_Bali.html" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/wall-street-select.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/warl.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/wnd.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/yahoo.jpg", url: "" },
];

const Logos = [
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/aston-hotels.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/hardrock-hotels-bali.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/hilton.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/hyatt.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/ibissytles.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/marriot.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/Mercure_Hotels.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/novotel.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/pullman.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/radison-hotels.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/swiss-belhotel.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/seraton.png'
];

const logoss = [
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/airasia.png',
    'https://storage.googleapis.com/bali-hotel-images/hotels-partner/jetstar.png',
];

export interface PartnerProps { }

export function Partner(Props: PartnerProps) {
    let Partner: any[] = [];
    let imgs = [];
    let keyImg: number = 0;
    for (let a of Logos) {
        keyImg++;
        imgs.push(
            <img key={`img${keyImg}`} src={a} className="col-md-3 col-sm-3 col-xs-3" alt="" />
        );
    }
    for (let a of logoss) {
        keyImg++;
        imgs.push(
            <img key={`img${keyImg}`} src={a} className="col-md-3 col-sm-3 col-xs-3" alt="" />
        );
    }

    let loop = 0;
    for (let t of Press) {
        loop++;
        let key = `Partner${loop}`;
        if (t.url !== "") {
            Partner.push(
                <a key={key} href={t.url} target="_blank" rel="noopener noreferrer">
                    <img src={t.imgPath} width="100%" alt="" />
                </a>
            );
        } else {
            Partner.push(<img key={key} src={t.imgPath} width="100%" alt="" />);
        }
    }

    return (
        <section style={{ margin: '30px 0 30px' }}>
            <Container  >
                <div className="row">
                    <div className="col-md-4" style={{ fontSize: 16 }}>
                        <h3>Our Partner</h3>
                        <p>
                            We partner with the leading international as well as local hotel brands to ensure the best possible stay for you in Bali. We also work with some of Bali’s leading charities to support both the island and its people
                        </p>
                        <p style={{ paddingTop: 30 }}>
                            Hotel review powered by <br />
                            <img alt="" src="https://storage.googleapis.com/bali-hotel-images/TA_logo_primary200.png" />
                        </p>

                        <p style={{ paddingTop: 30 }}>
                            Hotel Booking powered by
                            <img alt="" src="https://storage.googleapis.com/bali-hotel-images/hotelbed.png" />
                        </p>
                    </div>
                    <div className="col-md-8">
                        {imgs}
                    </div>
                </div>

            </Container>

        </section>

    ) 
}




