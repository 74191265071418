import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

export interface BaliholidysdVipSectionProps { }

export function BaliholidysdVipSection(Props: BaliholidysdVipSectionProps) {

    return (

        <section style={{ background: "url('/assets/images/vip-banner.jpg')", margin: 0, padding: '70px 0', backgroundSize: "cover" }}>
            <Container>

                <Row>
                    <Col md={6}>

                    </Col>
                    <Col md={6}>
                        <h2 style={{ textAlign: 'right', fontWeight: 800, textTransform: 'uppercase', color: "#df9b26", fontSize: 42 }}>Now You’re a Bali VIP! </h2>
                        <h3 style={{ textAlign: 'right', marginTop: 5, lineHeight: 1, textTransform: 'uppercase', color: "#FFF", fontSize: 36, fontWeight: 300 }}>Get More With </h3>
                        <h3 style={{ textAlign: 'right', marginTop: 5, lineHeight: 1, textTransform: 'uppercase', color: "#FFF", fontWeight: 300, fontSize: 36, marginRight: 50 }}>Every Booking</h3>
                        <p style={{ color: '#ccc' }}>
                            When you book for your <a href="https://balivillasandmore.com" rel="noopener noreferrer" target="_blank">villa</a> or <a href="https://balihotelsandmore.com" rel="noopener noreferrer" target="_blank">hotel</a> with us you get more than just a great price on a room. You get a holiday experience you wont forget. You see the difference is we live here in Bali so we have collected some of the best deals and offers not only on your accommodation but everything you do when you arrive. From free drinks, meals discounts, spa and salon discounts, free airport transfers, free <a href="https://balitoursandmore.com" rel="noopener noreferrer" target="_blank">tours</a> for kids and so much more.
                        </p>
                        <p style={{ color: '#ccc' }}>
                            Let us make your Bali holiday the best possible experience. Who knows more about Bali than the people that live here! We cant wait for you to arrive! We’ll save you!
                        </p>


                        <p style={{ textAlign: "center" }}><Button variant="warning" size="lg" style={{ color: "#fff" }} href="" >See More Info</Button></p>
                    </Col>

                </Row>
            </Container>
        </section>

    )

} 